<template>
  <div>
    <div class="d-flex align-center mt-2">
      <span class="text-caption font-weight-bold ms-4 me-2">Job & Prospek</span>
      <v-divider class="my-4" />
      <v-btn
        v-if="customer && currentTab === 0"
        color="primary"
        outlined
        x-small
        rounded
        class="mx-2"
        @click="$refs.jobForm.show(customer)"
      >
        Buat Job
      </v-btn>
      <v-btn
        v-if="customer && currentTab === 1"
        color="primary"
        outlined
        x-small
        rounded
        class="mx-2"
        @click="$refs.prospectForm.show(customer)"
      >
        Buat Prospek
      </v-btn>
      <v-btn
        v-if="customer && currentTab === 2"
        color="primary"
        outlined
        x-small
        rounded
        class="mx-2"
        @click="$refs.taskForm.show(customer)"
      >
        Buat Task
      </v-btn>
    </div>
    <div
      v-if="customer"
      class="px-4 d-flex flex-column align-start"
    >
      <v-tabs
        v-model="currentTab"
        grow
        show-arrows
        height="25"
        class="rounded pp-tabs activity-tabs mt-2"
      >
        <v-tab>
          <v-icon
            size="15"
            class="me-3"
          >
            {{ icons.mdiTicketAccount }}
          </v-icon>
          <span class="text-capitalize text-caption">Job</span>
        </v-tab>
        <v-tab>
          <v-icon
            size="15"
            class="me-3"
          >
            {{ icons.mdiTagHeart }}
          </v-icon>
          <span class="text-capitalize text-caption">Prospek</span>
        </v-tab>
        <v-tab>
          <v-icon
            size="15"
            class="me-3"
          >
            {{ icons.mdiCalendarCheck }}
          </v-icon>
          <span class="text-capitalize text-caption">Task</span>
        </v-tab>
      </v-tabs>
      <v-progress-linear
        v-if="loadingCust || loadingPros"
        indeterminate
      />
      <v-tabs-items v-model="currentTab">
        <v-tab-item>
          <PerfectScrollbar
            v-if="jobList.length && !loadingCust"
            :options="{
              useBothWheelAxes:true
            }"
            class="py-4 job-chat-max-width max-h-tabs"
          >
            <div
              v-for="data in jobList"
              :key="data.id"
              class="d-flex justify-space-between"
            >
              <div class="d-flex flex-column">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="text-caption font-weight-bold d-block primary--text mb-2 cursor-pointer"
                      v-bind="attrs"
                      @click="$router.push({ name: 'job-detail', params: { id: data.id } })"
                      v-on="on"
                    >{{ data.name }}</span>
                  </template>
                  <div class="d-flex flex-column">
                    <span>Dibuat : {{ dateFormat(data.created_at) }}</span>
                    <span>Terakhir diupdate : {{ dateFormat(data.updated_at) }}</span>
                  </div>
                </v-tooltip>
              </div>
              <div class="d-flex">
                <LimitElements
                  :elements="[{type: 'priority', content: data.priority}, {type:'status', content:data.status}, {type:'type', content: data.job_type}]"
                  :limit="1"
                >
                  <template #default="props">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          x-small
                          :color="props.data.type === 'priority' ? priorityColor(props.data.content.id) : props.data.content.color"
                          :class="`mr-1 ${props.data.type === 'priority' && 'v-chip-light-bg ' + priorityColor(props.data.content.id)+'--text'} ` "
                          v-on="on"
                        >
                          {{ props.data.content.name }}
                        </v-chip>
                      </template>
                      <span>Ekspetasi Job ditutup : {{ dateFormat(data.expected_close_date, 1) }}</span>
                    </v-tooltip>
                  </template>
                  <template #others-activator="props">
                    <v-chip
                      x-small
                      color="primary"
                      class="mr-1 v-chip-light-bg primary--text mb-2"
                    >
                      +{{ props.data.limit }}
                    </v-chip>
                  </template>
                </LimitElements>
              </div>
            </div>
          </PerfectScrollbar>
          <div
            v-else-if="!jobList.length && !loadingCust"
            class="d-flex justify-center align-center job-chat-max-width mt-16"
          >
            <p class="mb-0">
              Tidak ada job
            </p>
          </div>
        </v-tab-item>
        <v-tab-item>
          <PerfectScrollbar
            v-if="prospectList.length && !loadingPros"
            :options="{
              useBothWheelAxes:true
            }"
            class="py-4 job-chat-max-width max-h-tabs"
          >
            <div
              v-for="prospect in prospectList"
              :key="prospect.id"
              class="d-flex justify-space-between"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-caption font-weight-bold d-block primary--text mb-2 cursor-pointer"
                    v-bind="attrs"
                    @click="$router.push({ name: 'prospect-detail', params: { id: prospect.id } })"
                    v-on="on"
                  >{{ prospect.name }}</span>
                </template>
                <div class="d-flex flex-column">
                  <span>Dibuat : {{ prospect.created_at }}</span>
                  <span>Terakhir diupdate : {{ prospect.updated_at }}</span>
                </div>
              </v-tooltip>
              <div class="d-flex">
                <ChipDisplay
                  class="mr-2"
                  :x-small="true"
                  :status="prospect.status"
                />
                <v-tooltip
                  top
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="20"
                      class="text--disabled mr-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ resolveIconVisibility(prospect.visibility.id) }}
                    </v-icon>
                  </template>
                  <span class="d-block">Visibilitas: {{ prospect.visibility.name }}</span>
                  <span>{{ prospect.visibility.description }}</span>
                </v-tooltip>
              </div>
            </div>
          </PerfectScrollbar>
          <div
            v-else-if="!prospectList.length && !loadingPros"
            class="d-flex justify-center align-center job-chat-max-width mt-16"
          >
            <p class="mb-0">
              Tidak ada prospek
            </p>
          </div>
        </v-tab-item>
        <v-tab-item>
          <PerfectScrollbar
            v-if="taskList.length && !loadingTask"
            :options="{
              useBothWheelAxes:true
            }"
            class="py-4 job-chat-max-width max-h-tabs"
          >
            <div
              v-for="task in taskList"
              :key="task.id"
              class="d-flex justify-space-between"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-caption font-weight-bold d-block primary--text mb-2 cursor-pointer"
                    v-bind="attrs"
                    v-on="on"
                    @click="$refs.taskDetail.show(task.id)"
                  >{{ ellipsis(task.subject, 20) }}</span>
                </template>
                <div class="d-flex flex-column">
                  <span>Dibuat : {{ dateFormat(task.created_at ) }}</span>
                  <span>Terakhir diupdate : {{ dateFormat(task.updated_at) }}</span>
                </div>
              </v-tooltip>
              <div class="d-flex">
                <v-tooltip
                  v-if="task.completed_at"
                  top
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      x-small
                      color="success"
                      class="mr-2 v-chip-light-bg success--text"
                      v-on="on"
                    >
                      Selesai
                    </v-chip>
                  </template>
                  <span class="d-block">Tanggal diselesaikan : {{ dateFormat(task.completed_at) }}</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  top
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      x-small
                      color="warning"
                      class="mr-2 v-chip-light-bg warning--text"
                      v-on="on"
                    >
                      Belum Selesai
                    </v-chip>
                  </template>
                  <span class="d-block">Berakhir pada : {{ dateFormat(task.end_date) }}</span>
                </v-tooltip>
                <v-tooltip
                  top
                  allow-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="20"
                      class="text--disabled mr-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ resolveIconVisibility(task.visibility.id) }}
                    </v-icon>
                  </template>
                  <span class="d-block">Visibilitas: {{ task.visibility.name }}</span>
                  <span>{{ task.visibility.description }}</span>
                </v-tooltip>
              </div>
            </div>
          </PerfectScrollbar>
          <div
            v-else-if="!taskList.length && !loadingTask"
            class="d-flex justify-center align-center job-chat-max-width mt-16"
          >
            <p class="mb-0">
              Tidak ada task
            </p>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div
      v-else
      class="px-4 d-flex flex-column align-start"
    >
      <span
        class="text--disabled text-caption"
      >
        Chat room belum tersambung ke customer, harap sambungkan customer terlebih dahulu sebelum membuat job atau prospek.
      </span>
    </div>

    <JobForm
      ref="jobForm"
      @success="fetchJobs"
    />
    <ProspectForm
      ref="prospectForm"
      @success="fetchProspect"
    />
    <TaskForm
      ref="taskForm"
      @success="fetchTasks"
    />
    <TaskDetail
      ref="taskDetail"
    />
  </div>
</template>

<script>
import JobForm from '@/views/job/components/JobForm.vue'
import useJob from '@/composables/useJob'
import { computed, onMounted, ref } from '@vue/composition-api'
import { ellipsis } from '@core/utils/filter'
import dateFormat from '@/utils/dateFormat'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import {
  mdiTagHeart, mdiTicketAccount, mdiCalendarCheck,
} from '@mdi/js'
import ProspectForm from '@/views/prospect/component/ProspectForm.vue'
import LimitElements from '@/components/misc/LimitElements.vue'
import useProspect from '@/composables/useProspect'
import ChipDisplay from '@/views/prospect/component/ChipDisplay.vue'
import useVisibility from '@/composables/useVisibility'
import TaskForm from '@/views/activity/task/TaskForm.vue'
import useTask from '@/composables/useTask'
import TaskDetail from '@/views/activity/task/TaskDetail.vue'

export default {
  components: {
    TaskDetail,
    JobForm,
    PerfectScrollbar,
    ProspectForm,
    LimitElements,
    ChipDisplay,
    TaskForm,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const currentTab = ref(0)
    const custId = computed(() => props.customer.id)
    const {
      jobList, fetchJobs, jobFilter, priorityColor,
    } = useJob()
    const { taskList, fetchTasks, taskFilter } = useTask()
    const { resolveIconVisibility } = useVisibility()
    const { prospectList, fetchProspect, prospectFilter } = useProspect()
    const loadingCust = ref(false)
    const loadingPros = ref(false)
    const loadingTask = ref(false)
    onMounted(() => {
      if (custId.value) {
        jobFilter.value.filter = { ...jobFilter.value.filter, customer: custId }
        prospectFilter.value = { ...prospectFilter.value, customer: [custId.value] }
        taskFilter.value.filter = { ...taskFilter.value.filter, customer: custId.value }
        loadingCust.value = true
        loadingPros.value = true
        loadingTask.value = true
        fetchJobs().then(() => {
          loadingCust.value = false
        })
        fetchProspect().then(() => {
          loadingPros.value = false
        })
        fetchTasks().then(() => {
          loadingTask.value = false
        })
      }
    })

    return {
      resolveIconVisibility,
      jobList,
      taskList,
      prospectList,
      loadingCust,
      loadingPros,
      loadingTask,
      fetchJobs,
      fetchTasks,
      fetchProspect,
      ellipsis,
      dateFormat,
      currentTab,
      priorityColor,
      icons: {
        mdiTagHeart,
        mdiTicketAccount,
        mdiCalendarCheck,
      },
    }
  },
}
</script>

<style lang="scss">
  .pp-tabs {
    &.v-tabs:not(.v-tabs--vertical) {
      box-shadow: none !important;
      .v-tabs-bar {
        background-color: transparent !important;
      }
    }
  }
.job-chat-max-width{
  max-width: 290px;
  min-width: 290px;
}
.max-h-tabs {
  max-height: 18rem;
}

</style>
